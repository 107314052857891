import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';

import ChevronIcon from 'assets/chevron.svg';
import ChevronDisabledIcon from 'assets/chevron-disabled.svg';
import Icon from 'components/Icon';
import ButtonTitle from 'components/Text/ButtonTitle';
import { sizes } from 'styles';

import BaseButton from '../BaseButton';

const FormButton = ({ hideIcon, disabled, children, className, titleClassName, red, loading, ...otherProps }) => {
  const theme = useTheme();

  return (
    <BaseButton
      {...otherProps}
      disabled={disabled}
      darkLoader
      loading={loading}
      css={css`
        height: ${sizes.buttonHeightBig};
        display: flex;
        align-items: center;
        justify-content: ${loading || hideIcon ? 'center' : 'space-between'};
        padding: 0 40px;
        box-shadow: 0px 4px 4px rgba(216, 221, 240, 0.2);
        border-radius: 100px;
        background-color: ${red ? theme.accent : theme.primary};
        cursor: pointer;
        text-align: ${hideIcon ? 'center' : 'left'};

        ${disabled &&
        `
          cursor: not-allowed;
          background-color: ${theme.lightGray};
          border: 1px solid ${theme.lightPurple};
        `}
      `}
      className={className}
    >
      <ButtonTitle
        css={css`
          color: ${red ? '#fff' : theme.secondary};

          ${disabled && `color:  ${theme.lightPurple};`}

          ${titleClassName};
        `}
      >
        {children}
      </ButtonTitle>
      {!hideIcon && (
        <Icon
          src={disabled ? ChevronDisabledIcon : ChevronIcon}
          alt="Next"
          css={css`
            height: 20px;
            width: 20px;
            transform: rotate(180deg);
            margin-left: 40px;
          `}
        />
      )}
    </BaseButton>
  );
};

FormButton.propTypes = {
  titleClassName: PropTypes.shape({}),
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.string.isRequired,
  red: PropTypes.bool,
  loading: PropTypes.bool,
};

export default React.memo(FormButton);
