import React from 'react';
import { css } from '@emotion/react';
import NextErrorComponent, { ErrorProps } from 'next/error';
import { NextSeo } from 'next-seo';

import NoDataIcon from 'assets/no-data.svg';
import FormButton from 'components/DeprecatedButton/FormButton';
import FullScreenPlaceholder from 'components/FullScreenPlaceholder';
import { ComponentStatics, GetInitialProps } from 'types';

const getTitle = (status) => {
  if (status === 403) {
    return "Sorry, you don't have access to this page.";
  }

  if (status === 404) {
    return "Whoops! We can't find the page you're looking for.";
  }

  if (status === 500) {
    return 'Whoops! We have encountered a problem.';
  }

  return `Sorry, we have encountered a problem: ${status} error.`;
};

const getDescription = (status) => `Error: ${status}. Please contact the support team for help: hello@paritynow.co.`;

interface Props extends ErrorProps {
  hasGetInitialPropsRun?: boolean;
  err?: any;
}

const OverwrittenError: React.FC<Props> & ComponentStatics = React.memo(
  ({ hasGetInitialPropsRun, err, statusCode }) => {
    if (!hasGetInitialPropsRun && err) {
      // getInitialProps is not called in case of
      // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
      // err via _app.js so it can be captured
      import('@sentry/nextjs')
        .then((Sentry) => {
          Sentry.captureException(err);
        })
        .catch();
      // Flushing is not required in this case as it only happens on the client
    }

    const title = getTitle(statusCode);
    const description = getDescription(statusCode);

    return (
      <>
        <NextSeo title={`${title}`} description={description} />
        <FullScreenPlaceholder title={title} description={description} image={NoDataIcon}>
          <div
            css={css`
              margin: 24px auto;
            `}
          >
            <FormButton element="link" href="/">
              Go Back
            </FormButton>
          </div>
        </FullScreenPlaceholder>
      </>
    );
  }
);

OverwrittenError.hideHeader = true;

OverwrittenError.getInitialProps = (async (ctx) => {
  const errorInitialProps: Props = await NextErrorComponent.getInitialProps(ctx);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  const Sentry = await import('@sentry/nextjs');

  if (ctx.err) {
    Sentry.captureException(ctx.err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await Sentry.flush(2000);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${ctx.asPath}`));
  await Sentry.flush(2000);

  return errorInitialProps;
}) as GetInitialProps<Props>;

export default OverwrittenError;
