import styled from '@emotion/styled';

import * as styles from 'styles';

export default styled.span`
  font-size: 16px;
  line-height: 28px;
  color: ${(props) => props.theme.secondary};
  ${styles.respondTo.mobile} {
    font-size: 14px;
    line-height: 24px;
  }
`;
