import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Image from 'next/image';

import Body from 'components/Text/Body';
import Headline from 'components/Text/Headline';
import * as styles from 'styles';

const FullScreenPlaceholder = ({ children, description, image, title }) => (
  <div
    css={css`
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      max-width: 700px;
      padding: 0 24px;

      h1 {
        text-align: center;
      }

      p {
        margin-top: 24px;
        text-align: center;
      }
    `}
  >
    <div
      css={css`
        width: 120px;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        ${styles.respondTo.mobile} {
          width: 64px;
          height: 64px;
          margin-bottom: 24px;
        }
      `}
    >
      <Image alt={title} src={image} width={120} height={120} />
    </div>
    <Headline as="h1">{title}</Headline>
    <Body as="p">{description}</Body>

    {children}
  </div>
);

FullScreenPlaceholder.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default React.memo(FullScreenPlaceholder);
