import styled from '@emotion/styled';

import * as styles from 'styles';

export default styled.h1`
  font-weight: 800;
  font-style: italic;
  line-height: ${(props) => (props.small ? '32px' : '48px')};
  font-size: ${(props) => (props.small ? '28px' : '36px')};
  letter-spacing: ${(props) => (props.small ? '0.8px' : '1px')};
  color: ${(props) => props.theme.secondary}}

  ${styles.respondTo.mobile} {
    line-height: ${(props) => (props.small ? '28px' : '32px')};
    font-size: ${(props) => (props.small ? '24px' : '28px')};
    letter-spacing: ${(props) => (props.small ? '0.6px' : '0.8px')};
  }
`;
