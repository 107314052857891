import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

import Loader from 'components/Loader';

const BaseButton = ({
  children,
  disabled,
  element,
  openNewTab,
  loading,
  onClick,
  resolve,
  href,
  className,
  darkLoader,
  ...otherProps
}) => {
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const onClickDoResolve = async (event) => {
    try {
      setButtonLoading(true);
      await resolve(event);
      setButtonLoading(false);
    } catch (e) {
      setButtonLoading(false);
    }
  };

  const linkProps = {
    onClick,
    target: openNewTab && '_blank',
    rel: openNewTab && 'noopener noreferrer',
    'data-testid': otherProps['data-testid'],
  };

  if (element === 'link') {
    return (
      <Link {...otherProps} href={href}>
        <a {...linkProps} className={className}>
          {loading ? <Loader darkLoader={darkLoader} /> : children}
        </a>
      </Link>
    );
  }

  if (element === 'a') {
    return (
      <a {...linkProps} href={href} className={className}>
        {loading ? <Loader darkLoader={darkLoader} /> : children}
      </a>
    );
  }

  return (
    <button
      disabled={loading || disabled || buttonLoading}
      type="button"
      onClick={resolve ? onClickDoResolve : onClick}
      className={className}
      {...otherProps}
    >
      {loading || buttonLoading ? <Loader darkLoader={darkLoader} /> : children}
    </button>
  );
};

BaseButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  element: PropTypes.oneOf(['link', 'button', 'a']),
  href: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool,
  resolve: PropTypes.func,
  darkLoader: PropTypes.bool,
};

BaseButton.defaultProps = {
  element: 'button',
};

export default React.memo(BaseButton);
