import styled from '@emotion/styled';

import * as styles from 'styles';

export default styled.span`
  font-weight: 600;
  text-transform: uppercase;
  line-height: ${(props) => (props.small ? '22px' : '24px')};
  letter-spacing: ${(props) => (props.small ? '2.2px' : '2.4px')};
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  font-family: ${(props) => props.theme.fonts.openSans};
  color: ${(props) => props.theme.secondary}}
  ${styles.respondTo.mobile} {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 1.2px;
  }
`;
